import React from "react";
import "../../App.css";
import { weekDayMap } from "../../consts";

const SingleHour = ({
  hour,
  setSignUpForLessonOpen,
  setValues,
  values,
  setLessonData,
}) => {
  const timeSlots = [];
  const startDay = 0;
  const endDay = 7;

  const addLeadingZeros = (num) => String(num).padStart(2, "0");

  for (let i = startDay; i < endDay; i++) {
    if (!hour) {
      timeSlots.push(<td key={`empty-${i}`} className="bg-light-gray" />);
      continue;
    }

    const lessonsInSameHour = hour.filter(
      (lesson) => weekDayMap[i] === lesson.week_day
    );

    if (lessonsInSameHour.length > 0) {
      const lessonGroup = lessonsInSameHour.map((lesson) => {
        const start = `${addLeadingZeros(lesson.start.hour)}:${addLeadingZeros(
          lesson.start.minute
        )}`;
        const end = `${addLeadingZeros(lesson.end.hour)}:${addLeadingZeros(
          lesson.end.minute
        )}`;

        return (
          <div key={lesson.lesson_id} className="lesson-block">
            <span
              className={`bg-${lesson.lesson_type.bg_color} padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13`}
              data-testId={`lesson-${lesson.lesson_id}`}
              onClick={() => {
                setSignUpForLessonOpen(true);
                setValues({
                  ...values,
                  lesson_id: lesson.lesson_id,
                  lesson_name: lesson.lesson_type.friendly_name,
                  lesson_start: lesson.start,
                  lesson_week_day: lesson.week_day,
                  trainer: { full_name: lesson.trainer },
                  lesson_type: lesson.lesson_type,
                  start: lesson.flat_start,
                  duration: lesson.lesson_duration,
                  is_free: lesson.is_free,
                  lesson_category_id: lesson.lesson_type.lesson_category_id,
                });
                setLessonData({
                  lesson_id: lesson.lesson_id,
                  lesson_name: lesson.lesson_type.friendly_name,
                  lesson_start: lesson.start,
                  lesson_week_day: lesson.week_day,
                  trainer: { full_name: lesson.trainer },
                  lesson_type: lesson.lesson_type,
                  start: lesson.flat_start,
                  duration: lesson.lesson_duration,
                  is_free: lesson.lesson_type.is_free,
                  lesson_category_id: lesson.lesson_type.lesson_category_id,
                });
              }}
            >
              {lesson.lesson_type.friendly_name}
            </span>
            <div className="margin-10px-top font-size14">
              {start} - {end}
            </div>
            <div
              data-testId={`lesson-${lesson.lesson_id}-slots`}
              className="font-size13"
            >
              {lesson.current_people}/{lesson.lesson_type.max_people}
            </div>
          </div>
        );
      });

      timeSlots.push(
        <td
          key={`lesson-group-${i}`}
          className="lesson-group-cell"
          rowSpan={1}
        >
          {lessonGroup}
        </td>
      );
    } else {
      timeSlots.push(<td key={`empty-${i}`} className="bg-light-gray" />);
    }
  }

  return <tr>{timeSlots}</tr>;
};

export default SingleHour;
