import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { auth } from "../../firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuthStore } from "../../store";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: 600 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  backgroundColor: "#5c4033",
  padding: "10px 10px",
  fontSize: "15px",
  marginTop: "16px",
};

const errorMessages = {
  "auth/invalid-credential": "Nieprawidłowe dane hasło.",
  "auth/email-already-in-use": "Ten email jest już w użyciu.",
  "auth/invalid-email": "Nieprawidłowy email.",
  "auth/operation-not-allowed": "Operacja nie jest dozwolona.",
  "auth/weak-password": "Hasło jest zbyt słabe.",
  "auth/user-disabled": "Konto użytkownika zostało zablokowane.",
  "auth/user-not-found": "Nie znaleziono użytkownika z tym adresem email.",
  "auth/wrong-password": "Nieprawidłowe hasło.",
  "auth/requires-recent-login": "Wymagane ponowne zalogowanie.",
};

const getErrorMessage = (errorCode) => {
  return errorMessages[errorCode] || "Wystąpił nieznany błąd.";
};

const ReauthenticateModal = ({
  open,
  handleClose,
  showAlert,
  handleCloseMainModal,
}) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { email, userId, name, lastName, phone } = useAuthStore((state) => ({
    email: state.email,
    userId: state.userId,
    name: state.name,
    lastName: state.lastName,
    phone: state.phone,
  }));

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(""); // Clear the error message on password change
  };

  const handleReauthenticate = () => {
    if (!email.trim() || !password.trim()) {
      setError("Proszę wypełnić oba pola: email i hasło");
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then(async (result) => {
        setError(""); // Clear the error message on successful reauthentication
        handleClose();
        handleUpdate(result.user);
      })
      .catch((error) => {
        setError(getErrorMessage(error.code));
      });
  };

  const handleUpdate = async (reauthenticatedUser) => {
    setLoading(true);
    const idToken = await reauthenticatedUser.getIdToken();
    fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}/user/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        email,
        phone,
        full_name: `${name} ${lastName}`,
        firebase_uid: reauthenticatedUser.uid,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        setLoading(false);
        showAlert("Moje dane zostały pomyślnie zaktualizowane", "success");
        handleCloseMainModal();
      })
      .catch((error) => {
        setLoading(false);
        setError("Nie udało się zaktualizować informacji o użytkowniku");
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="reauthenticate-modal-title"
      aria-describedby="reauthenticate-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="reauthenticate-modal-title" variant="h6" component="h2">
          Ponowna Autoryzacja
        </Typography>
        <Typography id="reauthenticate-modal-description" sx={{ mt: 2 }}>
          Proszę wpisać swoje hasło, aby kontynuować.
        </Typography>
        <TextField
          label="Hasło"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          fullWidth
          margin="normal"
        />
        {error && <Typography color="error">{error}</Typography>}
        <Button
          variant="contained"
          color="primary"
          onClick={handleReauthenticate}
          sx={{ mt: 2 }}
          disabled={loading}
          style={buttonStyle}
        >
          {loading ? <CircularProgress size={24} /> : "Zaloguj się ponownie"}
        </Button>
      </Box>
    </Modal>
  );
};

export default ReauthenticateModal;
