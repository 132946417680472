import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardActionArea,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import "../../App.css";
import StoreModal from "../StoreModal"; // import the StoreModal component
import { useAuthStore } from "../../store"; // Import the store hook

const SignUpForLessonForm = ({
  setFormValuesParent,
  lessonType,
  isLessonFree,
  lessonCategoryId,
  lessonId,
}) => {
  const [signInMethod, setSignInMethod] = useState(
    isLessonFree ? "pojedyncza" : null
  );
  const [selectedPass, setSelectedPass] = useState(null);
  const [storeModalOpen, setStoreModalOpen] = useState(false);

  const { passes: storePasses } = useAuthStore((state) => ({
    passes: state.passes,
  }));

  useEffect(() => {
    setFormValuesParent({ signInMethod, selectedPass });
  }, [signInMethod, selectedPass, setFormValuesParent]);

  const handleSignInMethodSelection = (method) => {
    setSignInMethod(method);
    setSelectedPass(null); // Reset selected pass when method changes
  };

  const handlePassSelection = (pass) => {
    setSelectedPass(pass);
  };

  const handleOpenStoreModal = () => {
    setStoreModalOpen(true);
  };

  const filteredPasses =
    signInMethod === "karnet"
      ? storePasses.filter((pass) =>
          pass.lesson_categories_for_pass.some(
            (lesson) => lesson.name === lessonType
          )
        )
      : [];

  return (
    <Box>
      {isLessonFree ? (
        <Typography variant="h6" align="center" style={{ color: "green" }}>
          Zajęcia są darmowe
        </Typography>
      ) : (
        <Typography variant="h6" align="center">
          Wybierz metodę zapisu
        </Typography>
      )}
      {!isLessonFree && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <Button
            variant="contained"
            color={signInMethod === "pojedyncza" ? "primary" : "inherit"}
            onClick={() => handleSignInMethodSelection("pojedyncza")}
          >
            Pojedyncza lekcja
          </Button>
          <Button
            variant="contained"
            color={signInMethod === "karnet" ? "primary" : "inherit"}
            onClick={() => handleSignInMethodSelection("karnet")}
          >
            Karnet
          </Button>
        </Box>
      )}
      {signInMethod === "karnet" && filteredPasses.length > 0 && (
        <Box sx={{ marginTop: "20px" }}>
          <Typography variant="subtitle1">Dostępne karnety:</Typography>
          <Grid container spacing={2}>
            {filteredPasses.map((pass, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  variant="outlined"
                  onClick={() => handlePassSelection(pass)}
                  sx={{
                    backgroundColor: selectedPass === pass ? "#f0f0f0" : "#fff",
                    cursor: "pointer",
                    marginBottom: "20px",
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Typography variant="h6">
                        {pass.pass_type_name}
                      </Typography>
                      <Box sx={{ marginTop: "10px" }}>
                        <Typography variant="body1">
                          Ważne do: {pass.expiry_date}
                        </Typography>
                        <Table size="small" sx={{ marginTop: "10px" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Rodzaj lekcji</TableCell>
                              <TableCell>Pozostałe lekcje</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pass.lesson_categories_for_pass.map(
                              (lesson, idx) => (
                                <TableRow key={idx}>
                                  <TableCell>{lesson.name}</TableCell>
                                  <TableCell>
                                    {lesson.remaining_lessons}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {signInMethod === "karnet" && filteredPasses.length === 0 && (
        <Box sx={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenStoreModal} // open the StoreModal
            sx={{ marginTop: "10px" }}
          >
            Kup karnet
          </Button>
        </Box>
      )}
      <StoreModal
        open={storeModalOpen}
        onClose={() => setStoreModalOpen(false)}
        lessonCategoryId={lessonCategoryId}
        lessonId={lessonId}
      />
    </Box>
  );
};

export const isLessonAlreadySignedUp = (lessonId) => {
  const { lessons } = useAuthStore.getState();
  return lessons.some((lesson) => lesson.id === lessonId);
};

export default SignUpForLessonForm;
