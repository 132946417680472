import React from "react";
import "./assets/style/Footer.css";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 footer-contact">
              <h3>Natchnienie Studio</h3>
              <p>
                {process.env.REACT_APP_CONTACT_ADDRESS}
                <br />
                <strong>Telefon:</strong> {process.env.REACT_APP_CONTACT_PHONE1}
                <br />
                <strong>Email:</strong> {process.env.REACT_APP_CONTACT_EMAIL}
              </p>
            </div>

            <div className="col-lg-6 col-md-6 footer-links">
              <h4>Nasze media społecznościowe</h4>
              <div className="social-links mt-3">
                <a
                  href="https://www.facebook.com/profile.php?id=100090082840237"
                  className="facebook"
                >
                  <i className="bx bxl-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/natchnienie_studio/?fbclid=IwAR1Y9YAPyurX07yLmdCeIAep2YEgrZ0VSiklFroEdI6oW4hp6A7_IM9sHTA"
                  className="instagram"
                >
                  <i className="bx bxl-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container footer-bottom clearfix">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Natchnienie Studio</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          <div>
            <a href={`${process.env.REACT_APP_BACKEND_ADDRESS}/rodo/`} target="_blank">
              Klauzula RODO
            </a>
          </div>
          <div>
            <a href={`${process.env.PUBLIC_URL}/statute`} target="_blank">Regulamin</a>
          </div>
          <div>
            <a href={process.env.REACT_APP_PRIVACY_POLICY_LINK} target="_blank">
              Polityka prywatności
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
