import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import "./assets/style/CookieAlert.css";

function CookiesAlert({ onAccept }) {
  const [showAlert, setShowAlert] = useState(!document.cookie.includes('cookiePolicyAccepted=true'));

  function handleAccept() {
    document.cookie = 'cookiePolicyAccepted=true; max-age=31536000';
    setShowAlert(false);
    onAccept();
  }

  function onClose(event, resaon) {
    if (resaon === 'backdropClick' || resaon === 'escapeKeyDown' || resaon === 'backdropMouseDown') {
      return;
    }
    setShowAlert(false);
  }

  return (
    <Dialog data-testId="cookies_alert" open={showAlert} onClose={onClose} className="cookie-alert">
      <DialogTitle>Zanim przejdziesz dalej</DialogTitle>
      <DialogContent>
        <p data-testId="cookies_alert_text">Nasza strona internetowa używa plików cookies (tzw. ciasteczka) w celach statystycznych, reklamowych oraz funkcjonalnych. Dzięki nim możemy indywidualnie dostosować stronę do twoich potrzeb.</p>
      </DialogContent>
      <DialogActions>
        <Button data-testId="cookies_button" onClick={handleAccept} variant="contained" color="primary">
          Akceptuję
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CookiesAlert;