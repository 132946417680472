import React, { useState, useEffect } from "react";
import axios from "axios";
import { Collapse } from "react-bootstrap";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Alert,
} from "@mui/material";
import UserListForLesson from "./components/Admin/UserListForLesson"; // Adjust the path as necessary

const SingleUser = (props) => {
  const deleteUserOnClick = (id) => {
    deleteUser(id);
    handleClose();
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteUser = (user_id) => {
    const deleteEndpoint = `${process.env.REACT_APP_BACKEND_ADDRESS}/user_from_lesson/`;
    const httpReqHeaders = {
      headers: {
        "Content-Type": "application/json",
        "Access-Token": process.env.REACT_APP_API_TOKEN,
      },
      data: {
        lesson_id: props.lesson_id,
        user_id: user_id,
      },
    };
    axios
      .delete(deleteEndpoint, httpReqHeaders)
      .then((response) => {
        props.setrefreshUsers(!props.refreshUsers);
      })
      .catch();
  };

  const user = props.user;
  return (
    <div key={user.id.toString()}>
      <div className="row admin-elem">
        <div className="col-sm-6" data-aos="fade-up" data-aos-delay="100">
          <h4>
            {user.name} {user.phone}
          </h4>
        </div>
        <div className="col-sm-6" data-aos="fade-up" data-aos-delay="100">
          <div className="admin-button">
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                handleClickOpen();
              }}
            >
              Usuń
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {`Na pewno chcesz usunac ${user.name}?`}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleClose}>Nie</Button>
                <Button onClick={(e) => deleteUserOnClick(user.id)} autoFocus>
                  Tak
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

const Lesson = (props) => {
  const [isVisible, initHs] = useState(false);
  const [userListOpen, setUserListOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedPass, setSelectedPass] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const invokeCollapse = () => {
    return initHs(!isVisible);
  };

  const handleUserAdded = (user) => {
    setUserListOpen(false);
    props.setrefreshUsers(!props.refreshUsers);
    handleAddUserToLesson(user);
  };

  const handleAddUserToLesson = async (selectedUserData) => {
    setLoading(true);
    setError("");
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/user_to_lesson/`,
        {
          email: selectedUserData.email,
          phone: selectedUserData.phone,
          name: selectedUserData.full_name.split(" ")[0],
          surname: selectedUserData.full_name.split(" ")[1],
          lesson_id: props.lesson_id,
          full_name: selectedUserData.full_name,
          pass_id: selectedPass,
        },
        {
          headers: {
            "Access-Token": process.env.REACT_APP_API_TOKEN,
          },
        }
      );
      handleUserAdded(selectedUserData); // Pass the full user data back
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("User already signed up for this lesson");
      } else {
        setError("Failed to add user to lesson");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <li key={props.lesson_id}>
      <div>
        <a onClick={invokeCollapse}>
          {props.title}
          <i className="bx bx-chevron-down icon-show"></i>
        </a>
        <Button
          className="admin-button"
          color="success"
          variant="contained"
          onClick={() => setUserListOpen(true)}
        >
          Dodaj
        </Button>
        <Collapse in={isVisible}>
          <div
            id="accordion-list-1"
            className="collapse"
            data-bs-parent=".accordion-list"
          >
            <Button></Button>
            {props.users && props.users.length > 0 ? (
              props.users.map((user) => (
                <SingleUser
                  key={user.id}
                  user={user}
                  lesson_id={props.lesson_id}
                  refreshUsers={props.refreshUsers}
                  setrefreshUsers={props.setrefreshUsers}
                />
              ))
            ) : (
              <p>No users found.</p>
            )}
          </div>
        </Collapse>
        {userListOpen && (
          <UserListForLesson
            lessonId={props.lesson_id}
            onUserAdded={handleUserAdded}
            open={userListOpen}
            onClose={() => setUserListOpen(false)}
          />
        )}
        {props.signUpSucess && (
          <Alert variant="filled" severity="success">
            Zapisano na zajęcia!
          </Alert>
        )}
        {props.signUpError && Object.keys(props.signUpError).length !== 0 && (
          <Alert variant="filled" severity={props.signUpError.type}>
            {props.signUpError.message}
          </Alert>
        )}
      </div>
    </li>
  );
};

// Add default props
Lesson.defaultProps = {
  users: [],
  signUpSucess: false,
  signUpError: {},
};

export const DeleteUsers = () => {
  const lessons_endpoint = `${process.env.REACT_APP_BACKEND_ADDRESS}/lessons_attendees/`;
  const users_endpoint = `${process.env.REACT_APP_BACKEND_ADDRESS}/get_users/`;
  const [lessons, setLessons] = useState([]);
  const [refreshUsers, setrefreshUsers] = useState(false);
  const [isVisible, initHs] = useState(false);
  const [allUsers, setAllUsers] = useState({});
  const invokeCollapse = () => {
    return initHs(!isVisible);
  };
  const config = {
    headers: {
      "Access-Token": process.env.REACT_APP_API_TOKEN,
    },
  };
  useEffect(() => {
    axios
      .get(lessons_endpoint, config)
      .then((response) => {
        setLessons(response.data.lessons);
      })
      .catch();
  }, [refreshUsers]);

  useEffect(() => {
    axios
      .get(users_endpoint, config)
      .then((response) => {
        setAllUsers(response.data.users);
      })
      .catch();
  }, []);

  return (
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <a onClick={invokeCollapse}>
          Usuń/Dodaj kogoś z zajęć
          <i className="bx bx-chevron-down icon-show"></i>
        </a>
      </div>
      <Collapse in={isVisible}>
        <div className="row">
          <div
            className="col-lg-12 pt-4 pt-lg-0 content"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <div className="accordion-list">
              {lessons.map((lesson) => (
                <ul key={lesson.id}>
                  <Lesson
                    title={lesson.name}
                    users={lesson.attendees || []}
                    lesson_id={lesson.id}
                    lesson={lesson.lesson}
                    setrefreshUsers={setrefreshUsers}
                    refreshUsers={refreshUsers}
                    allUsers={allUsers}
                  />
                </ul>
              ))}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
