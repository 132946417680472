// store.js
import create from "zustand";
import axios from "axios";

export const useAuthStore = create((set) => ({
  email: "",
  name: "",
  lastName: "",
  phone: "",
  isAuthenticated: false,
  emailVerified: false,
  userId: "",
  lessons: [],
  passes: [],
  user: null,
  setEmail: (email) => set({ email }),
  setEmailVerified: (emailVerified) => set({ emailVerified }),
  setPhone: (phone) => set({ phone }),
  setFirstName: (name) => set({ name }),
  setLastName: (lastName) => set({ lastName }),
  setUserDetails: ({
    email,
    name,
    lastName,
    phone,
    isAuthenticated,
    userId,
    firebaseUid,
  }) =>
    set({ email, name, lastName, phone, isAuthenticated, userId, firebaseUid }),
  setLessons: (lessons) => set({ lessons }),
  setPasses: (passes) => set({ passes: Array.isArray(passes) ? passes : [] }), // Ensure passes is an array
  setUser: (user) => set({ user }),
  addLesson: (lesson) =>
    set((state) => ({ lessons: [...state.lessons, lesson] })),
  clearUserDetails: () =>
    set({
      email: "",
      name: "",
      lastName: "",
      phone: "",
      isAuthenticated: false,
      userId: "",
      firebaseUid: "",
      lessons: [],
      passes: [], // Clear passes when clearing user details
    }),
  fetchLessons: async () => {
    const { user } = useAuthStore.getState();
    if (!user) return;
    const idToken = await user.getIdToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/user/me/lessons`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      set({ lessons: response.data.objects });
    } catch (error) {
      console.error("Failed to fetch lessons", error);
    }
  },
}));
