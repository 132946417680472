import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import axios from "axios";

const PassManagement = ({ onPassAdded }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [passTypes, setPassTypes] = useState([]);
  const [lessonCategories, setLessonCategories] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPassType, setSelectedPassType] = useState(null);
  const [lessonCategoryCounts, setLessonCategoryCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState("");

  // Calculate the default date: three months from today
  const defaultDate = new Date();
  defaultDate.setMonth(defaultDate.getMonth() + 3);
  const defaultDateString = defaultDate.toISOString().split("T")[0];

  const [selectedDate, setSelectedDate] = useState(defaultDateString); // Default date set to current date + 3 months
  const [selectedTime, setSelectedTime] = useState("00:01"); // Default time set to 00:01

  useEffect(() => {
    fetchUsersPassTypesAndLessonCategories();
  }, []);

  const fetchUsersPassTypesAndLessonCategories = async () => {
    setLoading(true);
    try {
      const [userResponse, passTypeResponse, lessonCategoryResponse] =
        await Promise.all([
          axios.get(
            `${process.env.REACT_APP_BACKEND_ADDRESS}/user/?sort=full_name`,
            {
              headers: { "Access-Token": process.env.REACT_APP_API_TOKEN },
            }
          ),
          axios.get(`${process.env.REACT_APP_BACKEND_ADDRESS}/pass-type/`, {
            headers: { "Access-Token": process.env.REACT_APP_API_TOKEN },
          }),
          axios.get(
            `${process.env.REACT_APP_BACKEND_ADDRESS}/lesson-category/`,
            {
              headers: { "Access-Token": process.env.REACT_APP_API_TOKEN },
            }
          ),
        ]);

      if (
        userResponse.status === 200 &&
        Array.isArray(userResponse.data.objects)
      ) {
        setUsers(userResponse.data.objects);
        setFilteredUsers(userResponse.data.objects);
      } else {
        throw new Error("Failed to fetch users");
      }

      if (
        passTypeResponse.status === 200 &&
        Array.isArray(passTypeResponse.data.objects)
      ) {
        setPassTypes(passTypeResponse.data.objects);
      } else {
        throw new Error("Failed to fetch pass types");
      }

      if (
        lessonCategoryResponse.status === 200 &&
        Array.isArray(lessonCategoryResponse.data.objects)
      ) {
        setLessonCategories(lessonCategoryResponse.data.objects);
      } else {
        throw new Error("Failed to fetch lesson categories");
      }
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const handleAddPass = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    // Combine date and time inputs into a single ISO date-time string
    const dateTime = new Date(`${selectedDate}T${selectedTime}`).toISOString();

    try {
      const lessonsCount = Object.keys(lessonCategoryCounts).map((key) => ({
        lesson_category_id: parseInt(key, 10),
        lessons_to_use: parseInt(lessonCategoryCounts[key], 10),
      }));

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/pass_to_user/`,
        {
          pass_type_name: selectedPassType.name,
          user_id: selectedUser.id,
          lesson_categories: lessonsCount,
          valid_from: dateTime, // Use the combined date-time string
        },
        {
          headers: {
            "Access-Token": process.env.REACT_APP_API_TOKEN,
          },
        }
      );

      if (response.status === 200) {
        setSuccess("Pass added successfully!");
        setError("");
        onPassAdded(response.data);
        handleClose();
      } else {
        setError("Failed to add pass");
        setSuccess("");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
    setSelectedPassType(null);
    setLessonCategoryCounts({});
    setError("");
    setSelectedDate(defaultDateString); // Reset date to default
    setSelectedTime("00:01"); // Reset time when dialog is closed
  };

  const handleUserChange = (event, value) => {
    setSelectedUser(value);
  };

  const handlePassTypeChange = (event) => {
    const selectedPass = event.target.value;
    setSelectedPassType(selectedPass);

    // Reset lessons count for new pass type
    const newLessonsCount = {};
    selectedPass.lesson_categories_for_pass_type.forEach((category) => {
      newLessonsCount[category.lesson_category_id] = category.lessons_to_use;
    });
    setLessonCategoryCounts(newLessonsCount);
  };

  const handleLessonCountChange = (lessonCategoryId, count) => {
    setLessonCategoryCounts((prevState) => ({
      ...prevState,
      [lessonCategoryId]: count,
    }));
  };

  const getLessonCategoryName = (categoryId) => {
    const category = lessonCategories.find((cat) => cat.id === categoryId);
    return category ? category.name : `ID: ${categoryId}`;
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
      <a onClick={handleOpen}>
      Dodaj karnet użytkownikowi
          <i className="bx bx-chevron-down icon-show"></i>
        </a>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Dodaj nowy karnet</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Autocomplete
              options={filteredUsers}
              getOptionLabel={(option) => option.full_name}
              renderOption={(props, option) => (
                <Box component="li" {...props} sx={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: "100%" }}
                  >
                    <Typography variant="body1">{option.full_name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {option.email}
                    </Typography>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Wybierz użytkownika"
                  variant="outlined"
                  fullWidth
                />
              )}
              onChange={handleUserChange}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="pass-select-label">Wybierz karnet</InputLabel>
            <Select
              labelId="pass-select-label"
              value={selectedPassType}
              onChange={handlePassTypeChange}
              label="Wybierz karnet"
            >
              {passTypes.map((pass) => (
                <MenuItem key={pass.id} value={pass}>
                  {pass.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedPassType &&
            selectedPassType.lesson_categories_for_pass_type.map((category) => (
              <FormControl
                fullWidth
                sx={{ mb: 2 }}
                key={category.lesson_category_id}
              >
                <TextField
                  label={`Liczba lekcji dla kategorii: ${getLessonCategoryName(
                    category.lesson_category_id
                  )}`}
                  type="number"
                  variant="outlined"
                  value={
                    lessonCategoryCounts[category.lesson_category_id] || ""
                  }
                  onChange={(e) =>
                    handleLessonCountChange(
                      category.lesson_category_id,
                      e.target.value
                    )
                  }
                  fullWidth
                />
              </FormControl>
            ))}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label="Wybierz datę"
              type="date"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label="Wybierz czas"
              type="time"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
              fullWidth
            />
          </FormControl>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {success}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button
            onClick={handleAddPass}
            color="primary"
            disabled={
              loading ||
              !selectedUser ||
              !selectedPassType ||
              !selectedDate ||
              !selectedTime
            }
          >
            {loading ? <CircularProgress size={24} /> : "Add Pass"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PassManagement;
