import React from "react";
import "./assets/style/Contact.css";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Kontakt</h2>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex align-items-stretch">
            <div className="info">
              <div className="container">
                <div className="row">
                  <div className="address col-lg-4 col-md-6">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Wpadnij:</h4>
                    <p>{process.env.REACT_APP_CONTACT_ADDRESS}</p>
                  </div>
                  <div className="phone col-lg-4 col-md-6">
                    <i className="bi bi-phone"></i>
                    <h4>Zadzwoń:</h4>
                    <p>
                      {process.env.REACT_APP_CONTACT_PHONE1} /{" "}
                      {process.env.REACT_APP_CONTACT_PHONE2}
                    </p>
                  </div>

                  <div className="email col-lg-4 col-md-6">
                    <i className="bi bi-envelope"></i>
                    <h4>Napisz:</h4>
                    <p>{process.env.REACT_APP_CONTACT_EMAIL}</p>
                  </div>
                </div>
              </div>
              <iframe
                src={process.env.REACT_APP_CONTACT_GOOGLE_MAPS_LINK}
                frameBorder="0"
                style={{ border: 0, width: "100%", height: "290px" }}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
