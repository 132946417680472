import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Paper,
} from "@mui/material";
import axios from "axios";
import { weekDayMap } from "../../consts";

const Payments = () => {
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [lessonUsers, setLessonUsers] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLessonListVisible, setIsLessonListVisible] = useState(false); // State for toggling visibility of lesson list

  const handlePaymentsClick = async () => {
    try {
      // Toggle the visibility of the lessons list
      setIsLessonListVisible((prev) => !prev);

      // Fetch lessons only if they are not already fetched
      if (lessons.length === 0) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_ADDRESS}/payment/history/layout`,
          { headers: { "Access-Token": process.env.REACT_APP_API_TOKEN } }
        );
        setLessons(response.data.payments);
      }
    } catch (error) {
      console.error("Failed to fetch lessons:", error);
    }
  };

  // Open dialog for selected lesson
  const handleLessonClick = (lesson) => {
    setSelectedLesson(lesson);
    setLessonUsers({
      payed: lesson.payed,
      not_payed: lesson.not_payed,
    });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedLesson(null);
    setLessonUsers(null);
  };

  return (
    <Box>
      <Typography variant="h6">
        <div className="section-title">
          <a onClick={handlePaymentsClick}>
            Płatności
            <i className={`bx ${isLessonListVisible ? 'bx-chevron-up' : 'bx-chevron-down'} icon-show`}></i>
          </a>
        </div>
      </Typography>

      {/* Conditional rendering for lessons list */}
      {isLessonListVisible && lessons.length > 0 && (
        <Box display="flex" flexDirection="column">
          {lessons.map((lesson) => (
            <Box
              key={lesson.lesson_id}
              onClick={() => handleLessonClick(lesson)}
              sx={{
                padding: '10px',
                marginBottom: '8px',
                borderRadius: '4px',
                cursor: 'pointer',
                backgroundColor: '#f5f5f5', // Subtle background color
                color: '#333', // Neutral text color
                '&:hover': {
                  backgroundColor: '#e0e0e0', // Darker background on hover
                },
              }}
            >
              {lesson.lesson_name} - {lesson.lesson_start}
            </Box>
          ))}
        </Box>
      )}

      {/* Dialog for users in a selected lesson */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{selectedLesson?.lesson_name} - Lista Użytkowników</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Użytkownik</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lessonUsers && (
                  <>
                    {lessonUsers.payed.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>{user.name}</TableCell>
                        <TableCell sx={{ color: "green" }}>Paid</TableCell>
                      </TableRow>
                    ))}
                    {lessonUsers.not_payed.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>{user.name}</TableCell>
                        <TableCell sx={{ color: "red" }}>Not Paid</TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Button onClick={handleCloseDialog} color="error">
          Zamknij
        </Button>
      </Dialog>
    </Box>
  );
};

export default Payments;
