import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button, CircularProgress } from "@mui/material";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebase-config";
import { useAuthStore } from "../store";
import { validateEmail, validatePhone } from "../consts";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const errorMessages = {
  "auth/email-already-in-use": "Ten email jest już w użyciu.",
  "auth/invalid-email": "Nieprawidłowy email.",
  "auth/operation-not-allowed": "Operacja nie jest dozwolona.",
  "auth/weak-password":
    "Hasło jest zbyt słabe. Hasło musi mieć co najmniej 6 znaków, zawierać jedną dużą literę, jedną małą literę, jedną cyfrę i jeden znak specjalny.",
  "auth/user-disabled": "Konto użytkownika zostało zablokowane.",
};

const getErrorMessage = (errorCode) => {
  return errorMessages[errorCode] || "Wystąpił nieznany błąd.";
};

const RegistrationModal = ({ open, handleClose, showAlert }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const setUserDetails = useAuthStore((state) => state.setUserDetails);

  useEffect(() => {
    if (open) {
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setName("");
      setLastName("");
      setPhone("");
      setError("");
      setEmailError("");
      setPhoneError("");
    }
  }, [open]);

  const handleRegister = () => {
    if (password !== confirmPassword) {
      setError("Hasła nie pasują do siebie");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Nieprawidłowy format email.");
      return;
    } else {
      setEmailError("");
    }

    if (!validatePhone(phone)) {
      setPhoneError("Nieprawidłowy format numeru telefonu.");
      return;
    } else {
      setPhoneError("");
    }

    setLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        sendEmailVerification(userCredential.user)
          .then(() => {
            const user = userCredential.user;
            user.getIdToken().then((idToken) => {
              fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}/user/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
                body: JSON.stringify({
                  email: email,
                  phone: phone,
                  full_name: `${name} ${lastName}`,
                  firebase_uid: user.uid,
                }),
              })
                .then((response) => {
                  if (response.status === 409) {
                    throw new Error("phone-already-exists");
                  }
                  return response.json();
                })
                .then((data) => {
                  setLoading(false);
                  showAlert(
                    "Rejestracja zakończona pomyślnie. Sprawdź email, aby zweryfikować konto.",
                    "success"
                  );
                  setUserDetails({
                    email: data.email,
                    name: data.full_name.split(" ")[0] || "",
                    lastName: data.full_name.split(" ")[1] || "",
                    phone: data.phone,
                    isAuthenticated: true,
                    userId: data.id,
                    firebaseUid: data.firebase_uid,
                  });
                  handleClose();
                })
                .catch((error) => {
                  setLoading(false);
                  if (error.message === "phone-already-exists") {
                    setError("Numer telefonu już istnieje.");
                  } else {
                    setError(
                      "Failed to create user in backend: " + error.message
                    );
                  }
                });
            });
          })
          .catch((error) => {
            setLoading(false);
            setError("Failed to send email verification: " + error.message);
          });
      })
      .catch((error) => {
        setLoading(false);
        setError(getErrorMessage(error.code));
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="registration-modal-title"
    >
      <Box sx={modalStyle}>
        <h2 id="registration-modal-title">Zarejestruj się</h2>
        <TextField
          label="Imię"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Nazwisko"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Numer telefonu"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          margin="normal"
          error={!!phoneError}
          helperText={phoneError}
        />
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          error={!!emailError}
          helperText={emailError}
        />
        <TextField
          label="Hasło"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Potwierdź hasło"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <Button
          onClick={handleRegister}
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          style={{
            backgroundColor: "#5c4033",
            padding: "10px 10px",
            fontSize: "15px",
            marginTop: "16px",
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Zarejestruj się"}
        </Button>
      </Box>
    </Modal>
  );
};

export default RegistrationModal;
