import React from "react";
import pict from "./assets/img/team/natalia-zdjecie-bali.jpg";
import "./assets/style/AboutUs.css";

function AboutUs() {
  return (
    <section id="about-us" className="about-us section-bg">
      <div className="container-fluid" data-aos="fade-up">
        <div className="section-title">
          <h2>O nas</h2>
        </div>

        <div className="row">
          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
            <div className="content">
              <h3>
                <strong>O nas</strong>
              </h3>
              <p>
                Witaj w nat•chnieniu, miejscu gdzie pasja spotyka się z
                profesjonalizmem, a Ty możesz rozwijać się w swoim tempie,
                czerpiąc radość z każdej chwili spędzonej na macie. Naszym celem
                jest ciągły rozwój i doskonalenie, aby zapewnić Ci jak najlepsze
                doświadczenie i wsparcie na Twojej drodze do zdrowia.
              </p>
              <p>
                Zajęcia prowadzone są przez wyszkolonych instruktorów, którzy
                inspirują i pomagają odkrywać nowe możliwości ciała i umysłu.
                Dbamy o to, aby każdy trening był wyjątkowy, dopasowany do
                Twoich potrzeb i celów.
              </p>
              <p>
                Zajęcia odbywają się w małych grupach, ponieważ zależy nam na
                indywidualnym podejściu do każdego. Rozumiemy, że początek z
                nowym miejscem, ludźmi i może aktywnością bywa wyzwaniem,
                dlatego dokładamy wszelkich starań, abyś czuła się tutaj
                swobodnie i pewnie.
              </p>
              <p>Do zobaczenia na macie!</p>
            </div>
          </div>

          <div
            className="col-lg-5 align-items-stretch order-1 order-lg-2"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <img src={pict} className="img" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
