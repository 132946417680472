import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useAuthStore } from "../../store";
import { validatePhone } from "../../consts";

const buttonStyle = {
  backgroundColor: "#5c4033",
  padding: "10px 10px",
  fontSize: "15px",
  marginTop: "16px",
};

const UserInfoForm = ({
  user,
  showAlert,
  handleClose,
  setReauthenticateOpen,
}) => {
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");

  const {
    email,
    emailVerified,
    phone,
    lastName,
    name,
    setPhone,
    setLastName,
    setFirstName,
  } = useAuthStore((state) => ({
    email: state.email,
    emailVerified: state.emailVerified,
    phone: state.phone,
    lastName: state.lastName,
    name: state.name,
    setPhone: state.setPhone,
    setLastName: state.setLastName,
    setFirstName: state.setFirstName,
    setUserDetails: state.setUserDetails,
    userId: state.userId,
  }));

  const handlePhoneChange = (e) => {
    const phoneValue = e.target.value;
    setPhone(phoneValue);
    if (!validatePhone(phoneValue)) {
      setPhoneError("Nieprawidłowy format numeru telefonu.");
    } else {
      setPhoneError("");
    }
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleReauthenticateOpen = () => {
    setReauthenticateOpen(true);
    setError(""); // Clear the error message when opening reauthenticate modal
  };

  return (
    <Box>
      <Typography id="user-modal-title" variant="h6" component="h2">
        Moje dane
      </Typography>
      <Typography id="user-modal-description" sx={{ mt: 2 }}>
        {user && (
          <>
            <TextField
              label="Email"
              type="email"
              value={email}
              disabled
              fullWidth
              margin="normal"
            />
            <Typography variant="body2" color="textSecondary">
              Jeśli chcesz zmienić email, napisz na{" "}
              {process.env.REACT_APP_CONTACT_EMAIL}
            </Typography>
            <TextField
              label="Phone"
              type="tel"
              value={phone}
              onChange={handlePhoneChange}
              fullWidth
              margin="normal"
              error={!!phoneError}
              helperText={phoneError}
            />
            <TextField
              label="First Name"
              type="text"
              value={name}
              onChange={handleFirstNameChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Last Name"
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
              fullWidth
              margin="normal"
            />
            <Typography variant="body2">
              {emailVerified ? "Email zweryfikowany" : "Email niezweryfikowany"}
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleReauthenticateOpen}
                disabled={loading || !!phoneError}
                style={buttonStyle}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Zaktualizuj informacje"
                )}
              </Button>
            </div>
          </>
        )}
      </Typography>
    </Box>
  );
};

export default UserInfoForm;
