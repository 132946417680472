import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button, Alert } from "@mui/material";
import {
  signInWithEmailAndPassword,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase-config";
import RegistrationModal from "./Registration";
import LoginAdditionalInfoModal from "./LoginAdditionalInfoModal";
import "../assets/style/Login.css";
import { FcGoogle } from "react-icons/fc";
import { useAuthStore } from "../store";

const errorMessages = {
  "auth/invalid-credential": "Nieprawidłowe dane hasło.",
  "auth/email-already-in-use": "Ten email jest już w użyciu.",
  "auth/invalid-email": "Nieprawidłowy email.",
  "auth/operation-not-allowed": "Operacja nie jest dozwolona.",
  "auth/weak-password":
    "Hasło jest zbyt słabe. Hasło musi mieć co najmniej 6 znaków, zawierać jedną dużą literę, jedną małą literę, jedną cyfrę i jeden znak specjalny.",
  "auth/user-disabled": "Konto użytkownika zostało zablokowane.",
  "auth/user-not-found": "Nie znaleziono użytkownika z tym adresem email.",
  "auth/wrong-password": "Nieprawidłowe hasło.",
};

const getErrorMessage = (error) => {
  return (
    errorMessages[error.code] || error.message || "Wystąpił nieznany błąd."
  );
};

const LoginModal = ({ open, handleClose, showAlert }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRegistrationOpen, setRegistrationOpen] = useState(false);
  const [isAdditionalInfoOpen, setAdditionalInfoOpen] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const setUserDetails = useAuthStore((state) => state.setUserDetails);

  useEffect(() => {
    if (open) {
      setEmail("");
      setPassword("");
      setAlert({
        open: false,
        message: "",
        severity: "",
      });
    }
  }, [open]);

  const fetchUserData = async (token) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/user/me`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      setUserDetails({
        email: data.email,
        name: data.full_name.split(" ")[0] || "",
        lastName: data.full_name.split(" ")[1] || "",
        phone: data.phone,
        isAuthenticated: true,
        userId: data.id,
        firebaseUid: data.firebase_uid,
      });
      showAlert("Zalogowano pomyślnie", "success");
    } else {
      throw new Error("Failed to fetch user data");
    }
  };

  const signInWithEmail = () => {
    if (!email.trim() || !password.trim()) {
      setAlert({
        open: true,
        message: "Proszę wypełnić oba pola: email i hasło",
        severity: "warning",
      });
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then(async (result) => {
        const token = await result.user.getIdToken();
        await fetchUserData(token);
        handleClose();
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: getErrorMessage(error),
          severity: "error",
        });
      });
  };

  const createUserInBackend = async (user, idToken, additionalData) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/user/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          email: user.email,
          phone: additionalData.phone,
          full_name: `${additionalData.name} ${additionalData.lastName}`,
          firebase_uid: user.uid,
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      setUserDetails({
        email: data.email,
        name: data.full_name.split(" ")[0] || "",
        lastName: data.full_name.split(" ")[1] || "",
        phone: data.phone,
        isAuthenticated: true,
        userId: data.id,
        firebaseUid: data.firebase_uid,
      });
      showAlert("Konto zostało utworzone pomyślnie", "success");
      handleClose();
    } else {
      throw new Error("Failed to create user in backend");
    }
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          const token = await result.user.getIdToken();
          await fetchUserData(token);
          handleClose();
        }
      } catch (error) {
        setAlert({
          open: true,
          message: getErrorMessage(error),
          severity: "error",
        });
      }
    };

    handleRedirectResult();
  }, []);

  const handleAdditionalInfoSubmit = async (additionalData) => {
    const user = auth.currentUser;
    const idToken = await user.getIdToken();
    await createUserInBackend(user, idToken, additionalData);
    setAdditionalInfoOpen(false);
  };

  const resetPassword = () => {
    if (!email.trim()) {
      setAlert({
        open: true,
        message: "Proszę wpisać email",
        severity: "warning",
      });
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setAlert({
          open: true,
          message: "Email do resetowania hasła został wysłany!",
          severity: "success",
        });
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: getErrorMessage(error),
          severity: "error",
        });
      });
  };

  const handleRegistrationOpen = () => {
    handleClose();
    setRegistrationOpen(true);
  };

  const handleRegistrationClose = () => {
    setRegistrationOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="login-modal-title"
        aria-describedby="login-modal-description"
      >
        <Box className="modal-box">
          <h2 id="login-modal-title">Logowanie</h2>
          {alert.open && (
            <Alert
              severity={alert.severity}
              onClose={() =>
                setAlert({ open: false, message: "", severity: "" })
              }
              style={{ marginBottom: "16px" }}
            >
              {alert.message}
            </Alert>
          )}
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Hasło"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            onClick={signInWithEmail}
            fullWidth
            variant="contained"
            style={{
              backgroundColor: "#5c4033",
              padding: "10px 10px",
              fontSize: "15px",
              marginBottom: "5px",
            }}
          >
            Zaloguj się przez email
          </Button>
          <Button
            onClick={signInWithGoogle}
            fullWidth
            variant="outlined"
            className="modal-button-outlined"
            startIcon={<FcGoogle />}
            style={{
              padding: "10px 10px",
              fontSize: "15px",
              marginBottom: "3px",
            }}
          >
            Zaloguj się przez Google
          </Button>
          <Button
            onClick={resetPassword}
            fullWidth
            variant="text"
            style={{ color: "#5c4033", fontSize: "15px", marginBottom: "5px" }}
          >
            Zapomniałeś hasła?
          </Button>
          <Button
            onClick={handleRegistrationOpen}
            fullWidth
            variant="text"
            style={{ color: "#5c4033", fontSize: "15px", marginBottom: "5px" }}
          >
            Nie masz konta? Zarejestruj się
          </Button>
        </Box>
      </Modal>
      <RegistrationModal
        open={isRegistrationOpen}
        handleClose={handleRegistrationClose}
        showAlert={showAlert}
      />
      <LoginAdditionalInfoModal
        open={isAdditionalInfoOpen}
        handleClose={() => setAdditionalInfoOpen(false)}
        handleSubmit={handleAdditionalInfoSubmit}
      />
    </>
  );
};

export default LoginModal;
