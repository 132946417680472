import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAuthStore } from "../../store";
import StoreModal from "../StoreModal";

const AllPassesTable = () => {
  const [loading, setLoading] = useState(false);
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { passes } = useAuthStore((state) => ({
    user: state.user,
    passes: state.passes,
    setPasses: state.setPasses,
  }));

  const handleOpenStoreModal = () => {
    setStoreModalOpen(true);
  };

  return (
    <Box>
      {isMobile ? (
        <Paper
          sx={{
            backgroundColor: "#FAF3E0",
            padding: 2,
            maxHeight: "50vh",
            overflowY: "auto",
          }}
        >
          <List>
            {Array.isArray(passes) &&
              passes.map((pass, index) => (
                <React.Fragment key={index}>
                  <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start', backgroundColor: "#FFF8DC", marginBottom: 2 }}>
                    <ListItemText
                      primary={
                        <Typography variant="h6" component="div">
                          {pass.pass_type_name}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography variant="body2" color="textSecondary">
                            Ważny do: {new Date(pass.valid_until).toLocaleDateString()}
                          </Typography>
                          {pass.lesson_categories_for_pass.map((lesson, idx) => (
                            <Box key={idx} sx={{ mt: 1 }}>
                              <Typography component="div" variant="subtitle1">
                                {lesson.name}
                              </Typography>
                              <Typography component="div" variant="body2" color="textSecondary">
                                Do wykorzystania: {lesson.remaining_lessons}
                              </Typography>
                              <Typography component="div" variant="body2" color="textSecondary">
                                Wykorzystane: {lesson.consumed_lessons}
                              </Typography>
                            </Box>
                          ))}
                        </>
                      }
                    />
                  </ListItem>
                  {index < passes.length - 1 && <Divider />}
                </React.Fragment>
              ))}
          </List>
        </Paper>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "70vh",
            overflowY: "auto",
            backgroundColor: "#FAF3E0",
            width: "100%",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#D2B48C" }}>
                <TableCell>Typ karnetu</TableCell>
                <TableCell>Typ zajęć</TableCell>
                <TableCell>Do wykorzystania</TableCell>
                <TableCell>Wykorzystane</TableCell>
                <TableCell>Ważny do</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(passes) &&
                passes.map((pass, index) => (
                  <React.Fragment key={index}>
                    {pass.lesson_categories_for_pass.map((lesson, idx) => (
                      <TableRow key={idx} sx={{ backgroundColor: "#FFF8DC" }}>
                        {idx === 0 && (
                          <TableCell
                            rowSpan={pass.lesson_categories_for_pass.length}
                          >
                            {pass.pass_type_name}
                          </TableCell>
                        )}
                        <TableCell>{lesson.name}</TableCell>
                        <TableCell>{lesson.remaining_lessons}</TableCell>
                        <TableCell>{lesson.consumed_lessons}</TableCell>
                        {idx === 0 && (
                          <TableCell
                            rowSpan={pass.lesson_categories_for_pass.length}
                          >
                            {new Date(pass.valid_until).toLocaleDateString()}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenStoreModal}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Kup karnet"}
        </Button>
      </Box>

      <StoreModal
        open={storeModalOpen}
        onClose={() => setStoreModalOpen(false)}
      />
    </Box>
  );
};

export default AllPassesTable;