import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import "./assets/style/Pricing.css";

const CollapsedListElem = props => {
  const [isVisible, initHs] = useState(false);
  const invokeCollapse = () => {
    return initHs(!isVisible);
  };

  return (
    <li>
      <a onClick={invokeCollapse}>
        {props.title}
        <i className="bx bx-chevron-down icon-show"></i>
        <i className="bx bx-chevron-up icon-close"></i>
      </a>
      <Collapse in={isVisible}>
        <div
          id="accordion-list-1"
          className="collapse"
          data-bs-parent=".accordion-list"
        >
          <div className="row pricing-elem">
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div className="box">
                <h3>{props.first}</h3>
                <h4>
                  {props.price}
                  <sup>zł</sup>
                </h4>
              </div>
            </div>

            <div
              className="col-lg-6 mt-6 mt-lg-0"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="box featured">
                <h3>{props.second}</h3>
                <h4>
                  {props.carnet}
                  <sup>zł</sup>
                </h4>
              </div>
            </div>

            {props.additional ? (
              <div className="col-lg-12">
                <p>{props.additional}</p>
              </div>
            ) : null}
          </div>
        </div>
      </Collapse>
    </li>
  );
};

const Pricing = () => {
  return (
    <section id="pricing" className="pricing">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Cennik</h2>
        </div>
        <div className="row">
          <div
            className="col-lg-12 pt-4 pt-lg-0 content"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <div className="accordion-list">
              <ul>
                <CollapsedListElem
                  title="Yoga / Pilates"
                  price="40"
                  carnet="350"
                  first="Wejście jednorazowe"
                  second="Karnet 10 wejść"
                />
                <CollapsedListElem
                  title="Pozostałe zajęcia"
                  price="30"
                  carnet="250"
                  first="Wejście jednorazowe"
                  second="Karnet 10 wejść"
                />
                <CollapsedListElem
                  title="Treningi personalne"
                  price="150"
                  carnet="1400"
                  first="1 trening"
                  second="Pakiet 10 treningów"
                  additional="Zapisy przez telefon lub email"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
