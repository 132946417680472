import React, { useEffect, useState } from "react";
import { Box, Typography, Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "../assets/style/Statute.css";
import { urls } from "../Routes";

const Statute = () => {
  const [statutes, setStatutes] = useState([]);
  const [expandedContentIndex, setExpandedContentIndex] = useState({}); // Track expanded content items by statute index
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStatutes = async () => {
      try {
        const response = await fetch(urls.latestStatute, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Token": process.env.REACT_APP_API_TOKEN,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setStatutes(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching statutes:", error);
        setLoading(false);
      }
    };

    fetchStatutes();
  }, []);

  const handleToggle = (statuteIndex, contentIndex) => {
    setExpandedContentIndex((prevState) => ({
      ...prevState,
      [statuteIndex]:
        prevState[statuteIndex] === contentIndex ? null : contentIndex,
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (statutes.length === 0) {
    return <div>No statutes available</div>;
  }

  return (
    <div className="statute-container">
      {statutes.map((statute, statuteIndex) => (
        <div key={statute.id} className="statute-card">
          {statute.content.map((contentItem, contentIndex) => (
            <Box key={contentIndex} className="statute-item">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="h2">
                  {contentItem.title}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="body2"
                    color="primary"
                    onClick={() => handleToggle(statuteIndex, contentIndex)}
                    style={{ cursor: "pointer", marginRight: "8px" }}
                  >
                    Czytaj więcej
                  </Typography>
                  <IconButton
                    onClick={() => handleToggle(statuteIndex, contentIndex)}
                  >
                    {expandedContentIndex[statuteIndex] === contentIndex ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <Typography variant="h6" component="h3">
                {contentItem.sections[0].title}
              </Typography>
              <Typography variant="body1">
                {contentItem.sections[0].content}
              </Typography>
              <Collapse
                in={expandedContentIndex[statuteIndex] === contentIndex}
              >
                {contentItem.sections.slice(1).map((section, sectionIndex) => (
                  <Box key={sectionIndex} className="statute-section">
                    <Typography variant="h6" component="h3">
                      {section.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{ __html: section.content }}
                    />
                  </Box>
                ))}
              </Collapse>
            </Box>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Statute;
