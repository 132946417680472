import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import pict from "./assets/img/natchnienie-logo2.jpg";
import "./assets/style/Hero.css";

function Hero() {
  return (
    <section id="hero" className="d-flex align-items-center">
      <Container>
        <Row>
          <Col
            className="d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h1>Natchnienie studio</h1>
            <h2>Odnajdź natchnienie w naszym studiu</h2>
            <p className="quote text-center mt-3">
              "Jedna chwila może zmienić dzień, jeden dzień może zmienić życie, a jedno życie może zmienić świat." - Buddha
            </p>
          </Col>
          <div
            className="col-lg-6 order-1 order-lg-2 hero-img"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <img
              src={pict}
              className="img-fluid animated hero-circulated"
              alt=""
            />
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default Hero;
