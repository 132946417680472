import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Alert,
  DialogActions,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Checkbox,
} from "@mui/material";
import SignUpForLessonForm, {
  isLessonAlreadySignedUp,
} from "./SignUpForLessonForm";
import LoginModal from "../Login";
import "../../App.css";
import { useAuthStore } from "../../store";

// Define the global variable
let payDialogOn = true; // You can control this variable from anywhere

const monthsMap = {
  1: "Stycznia",
  2: "Lutego",
  3: "Marca",
  4: "Kwietnia",
  5: "Maja",
  6: "Czerwca",
  7: "Lipca",
  8: "Sierpnia",
  9: "Września",
  10: "Października",
  11: "Listopada",
  12: "Grudnia",
};

const SignUpForLessonModal = ({
  open,
  onClose,
  setsignUpSucess,
  setSignUpError,
  lessonData,
}) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    signInMethod: "",
    selectedPass: null,
  });
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [acceptRegulamin, setAcceptRegulamin] = useState(false);

  const {
    isAuthenticated,
    addLesson,
    email,
    name,
    lastName,
    phone,
    user,
    lessons,
    fetchLessons,
  } = useAuthStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    addLesson: state.addLesson,
    email: state.email,
    name: state.name,
    lastName: state.lastName,
    phone: state.phone,
    user: state.user,
    lessons: state.lessons,
    fetchLessons: state.fetchLessons,
  }));
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [passes, setPasses] = useState([]);

  useEffect(() => {
    if (open) {
      handleOpen();
    }
  }, [open]);

  useEffect(() => {
    if (!isAuthenticated && open) {
      handleLoginOpen();
    }
  }, [isAuthenticated, open]);

  useEffect(() => {
    if (isAuthenticated && open) {
      fetchLessons();
    }
  }, [isAuthenticated, open, fetchLessons]);

  useEffect(() => {
    if (formValues.signInMethod === "karnet" && open) {
      fetchPasses();
    }
  }, [formValues.signInMethod, open]);

  const handleLoginOpen = () => {
    onClose();
    setLoginOpen(true);
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
  };

  const fetchPasses = async () => {
    if (!isAuthenticated || !lessonData.lesson_id) return;
    const idToken = await user.getIdToken();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/pass/mine?active=true&lesson_id=${lessonData.lesson_id}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await response.json();
      if (Array.isArray(data.objects)) {
        setPasses(data.objects);
      } else {
        console.error("Invalid passes data format", data);
        setPasses([]);
      }
    } catch (error) {
      console.error("Failed to fetch passes", error);
      setPasses([]);
    }
  };

  const handleOpen = () => {
    if (isLessonAlreadySignedUp(lessonData.lesson_id)) {
      setSignUpError({
        message: "Już jesteś zapisana/y na te zajęcia. Do zobaczenia!",
        type: "warning",
      });
      setTimeout(() => setError(""), 5000);
      onClose();
      return;
    }
  };

  const handleSubmit = async () => {
    if (formValues.signInMethod === "karnet" && !formValues.selectedPass) {
      setError("Proszę wybrać karnet");
      return;
    }

    if (
      formValues.signInMethod === "pojedyncza" &&
      payDialogOn &&
      lessonData.is_free === false
    ) {
      setConfirmationOpen(true);
      return;
    }

    await handleUserToLesson();
  };

  const handleUserToLesson = async () => {
    const requestData = {
      lesson_id: lessonData.lesson_id,
      email: isAuthenticated ? email : null,
      name: isAuthenticated ? name : null,
      lastName: isAuthenticated ? lastName : null,
      phone: isAuthenticated ? phone : null,
      pass_id: formValues.selectedPass,
    };

    const idToken = await user.getIdToken();
    setLoading(true);

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/user_to_lesson`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (resp.data.status_code === 200) {
        setsignUpSucess(true);
        setSignUpError({});
        addLesson(lessonData);
        setLoading(false);
        onClose();
        setError("");
        return true;
      } else if (resp.data.status_code === 409) {
        if (resp.data.detail === "All lessons in pass are already used") {
          setSignUpError({
            message: "Wszystkie zajęcia z karnetu zostały już wykorzystane",
            type: "warning",
          });
        } else {
          setSignUpError({
            message: "Już jesteś zapisana/y na te zajęcia. Do zobaczenia!",
            type: "warning",
          });
        }
        setsignUpSucess(false);
      } else if (resp.data.status_code === 404) {
        setSignUpError({
          message:
            "Niestety maksymalna ilość osób na tych zajęciach została już osiągnięta :(",
          type: "error",
        });
        setsignUpSucess(false);
      } else {
        setSignUpError({ message: "Nieznany błąd :(", type: "error" });
        setsignUpSucess(false);
      }
    } catch (e) {
      const status = e.response?.status;
      if (status === 409) {
        if (e.response.data.detail === "All lessons in pass are already used") {
          setSignUpError({
            message: "Wszystkie zajęcia z karnetu zostały już wykorzystane",
            type: "warning",
          });
        } else {
          setSignUpError({
            message: "Już jesteś zapisana/y na te zajęcia. Do zobaczenia!",
            type: "warning",
          });
        }
        setsignUpSucess(false);
      } else if (status === 404) {
        setSignUpError({
          message:
            "Niestety maksymalna ilość osób na tych zajęciach została już osiągnięta :(",
          type: "error",
        });
        setsignUpSucess(false);
      } else {
        setSignUpError({ message: "Nieznany błąd :(", type: "error" });
        setsignUpSucess(false);
      }
    }

    setLoading(false);
    setError("");
    return false;
  };

  const handlePayment = async () => {
    if (!acceptRegulamin) {
      alert("Proszę zaakceptować Regulamin.");
      return;
    }

    const idToken = await user.getIdToken();

    try {
      const signUpSuccess = await handleUserToLesson();
      if (!signUpSuccess) {
        return;
      }

      const resp = await fetch(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/payment/create_order`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lesson_id: lessonData.lesson_id,
          }),
        }
      );

      if (!resp.ok) {
        throw new Error("Failed to create order");
      }

      const data = await resp.json();
      const redirectUrl = data;

      window.location.href = redirectUrl;
    } catch (paymentError) {
      console.error("Payment failed", paymentError);
      alert("Nie udało się dokonać płatności.");
    }
  };

  let lesson_name = lessonData.lesson_name || null;
  let lesson_start_day = lessonData.lesson_start
    ? lessonData.lesson_start.day
    : null;
  let lesson_start_month = lessonData.lesson_start
    ? monthsMap[lessonData.lesson_start.month]
    : null;
  let lesson_week_day = lessonData.lesson_week_day || null;
  let header = `Zapisz się na ${lesson_name}`;

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle textAlign="center">{header} </DialogTitle>
        <DialogTitle textAlign="center">
          {lesson_start_day} {lesson_start_month} ({lesson_week_day})
        </DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
              }}
            >
              <SignUpForLessonForm
                setFormValuesParent={setFormValues}
                lessonType={lessonData.lesson_name}
                isLessonFree={lessonData.is_free}
                lessonCategoryId={lessonData.lesson_category_id}
                lessonId={lessonData.lesson_id}
              />
              {formValues.signInMethod === "karnet" && (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Wybierz Karnet</FormLabel>
                  <RadioGroup
                    aria-label="passes"
                    name="passes"
                    value={formValues.selectedPass || ""}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        selectedPass: e.target.value,
                      })
                    }
                  >
                    {passes.map((pass) => {
                      const date = new Date(pass.valid_until);
                      const day = String(date.getDate()).padStart(2, "0");
                      const month = String(date.getMonth() + 1).padStart(
                        2,
                        "0"
                      );
                      const year = String(date.getFullYear()).slice(-2);
                      const formattedDate = `${day}-${month}-${year}`;

                      const lessonDetails = pass.lesson_categories_for_pass.map(
                        (category) => (
                          <div key={category.id}>
                            {category.name}: {category.remaining_lessons} do
                            wykorzystania{" "}
                          </div>
                        )
                      );

                      return (
                        <FormControlLabel
                          key={pass.id}
                          value={pass.id}
                          control={<Radio />}
                          label={
                            <div>
                              <strong>{pass.pass_type_name}</strong> (Ważne do:{" "}
                              {formattedDate}){lessonDetails}
                            </div>
                          }
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              )}
              {error && (
                <Alert
                  data-testId="sign-up-form-error"
                  variant="filled"
                  severity="error"
                >
                  {error}
                </Alert>
              )}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: "1.25rem" }}>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              onClose();
              setError("");
            }}
          >
            Anuluj
          </Button>
          <Button
            data-testId="sign-up-button"
            color="success"
            variant="contained"
            onClick={handleSubmit}
            disabled={
              loading ||
              !formValues.signInMethod ||
              (formValues.signInMethod === "karnet" && !formValues.selectedPass)
            }
          >
            {loading ? <CircularProgress size={24} /> : "Zapisz się"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
      >
        <DialogTitle>Czy chcesz zapłacić z góry za zajęcia?</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptRegulamin}
                onChange={(e) => setAcceptRegulamin(e.target.checked)}
                name="acceptRegulamin"
                color="primary"
              />
            }
            label={
              <Typography variant="body2">
                Akceptuję{" "}
                <a
                  href={`${process.env.PUBLIC_URL}/statute`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Regulamin
                </a>
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setConfirmationOpen(false);
              await handlePayment();
            }}
            style={{
              backgroundColor: acceptRegulamin ? "green" : "grey",
              color: "white",
            }}
            disabled={!acceptRegulamin}
          >
            Tak
          </Button>
          <Button
            onClick={async () => {
              setConfirmationOpen(false);
              await handleUserToLesson();
            }}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Nie
          </Button>
        </DialogActions>
      </Dialog>
      <LoginModal
        open={isLoginOpen}
        handleClose={handleLoginClose}
        showAlert={() => {}}
      />
    </>
  );
};

export { payDialogOn }; // Export the global variable
export default SignUpForLessonModal;
