import React from "react";
import "./assets/style/Offer.css";

function Offer() {
  return (
    <section id="offer" className="about-us section-bg">
      <div className="container-fluid" data-aos="fade-up">
        <div className="section-title">
          <h2>Oferta</h2>
        </div>

        <div className="row">
          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
            <div className="content">
              <h3>MINDFULLNESS</h3>

              <h4>
                <strong>Slow Yoga</strong><span className="highlight"> (Dobre dla początkujących)</span>
              </h4>
              <p>
                to forma jogi, która pozwala na głębokie rozciągnięcie mięśni,
                ich wzmocnienie i relaksację. Dzięki spokojnemu tempu, masz czas
                na pełne zaangażowanie w każdy ruch i oddech, co sprzyja
                regeneracji oraz redukcji stresu. To praktyka, która wzmacnia
                zarówno ciało, jak i umysł, pomagając odnaleźć wewnętrzny spokój
                i równowagę. Dobry wybór dla Ciebie, jeśli potrzebujesz
                wyciszenia, poprawy elastyczności, wzmocnienia oraz chcesz
                zadbać o swoje samopoczucie. (Dobre dla początkujących)
              </p>

              <h4>
                <strong>Vinyasa Flow Yoga</strong>
              </h4>
              <p>
                Vinyasa Flow to dynamiczna i płynna forma jogi, która
                synchronizuje ruch z oddechem, tworząc harmonijny przepływ
                energii. Każda sekwencja asan płynnie przechodzi w kolejną, co
                pozwala na pracę nad siłą, elastycznością i koncentracją. To
                intensywna, ale jednocześnie medytacyjna praktyka, która
                wzmacnia ciało i uspokaja umysł. Na tych zajęciach nauczysz się
                również pozycji odwróconych i różnych „peak pose”. Dobry wybór
                dla Ciebie, jeśli chcesz połączyć siłę z elastycznością, zyskać
                wewnętrzny spokój oraz poczuć głęboką więź między ciałem a
                umysłem.
              </p>

              <h4>
                <strong>Morning Bliss Yoga</strong>
              </h4>
              <p>
                To energetyzująca forma jogi, która pomaga rozpocząć dzień z
                pozytywnym nastawieniem i świeżą energią. Zajęcia łączą
                dynamiczne asany z głębokim oddechem, co sprawia, że ciało staje
                się elastyczne, a umysł klarowny i skoncentrowany. Regularna
                praktyka porannej jogi wpłynie na Twoją produktywność i nastrój
                przez cały dzień. Dobry wybór dla Ciebie, jeśli chcesz wzmocnić
                ciało, zyskać mentalną równowagę i zacząć dzień pełna energii.
              </p>

              <h3>KSZTAŁTOWANIE KOBIECEJ SYLWETKI</h3>

              <h4>
                <strong>Fit Body</strong>
              </h4>
              <p>
                Ten trening jest skoncentrowany na wzmacnianiu i modelowaniu
                całego ciała. Dzięki kombinacji ćwiczeń siłowych i cardio,
                poprawisz nie tylko swoją wytrzymałość, ale także spalisz zbędne
                kalorie, co pomoże w osiągnięciu smukłej sylwetki. Dobry wybór
                dla Ciebie, jeśli chcesz poprawić swoją kondycję, zbudować
                zgrabną sylwetkę i poczuć siłę w każdym ruchu.
              </p>

              <h4>
                <strong>Trening Obwodowy</strong> <span className="new"> (NOWOŚĆ)</span>
              </h4>
              <p>
                Trening obwodowy to intensywna sesja, która łączy elementy
                siłowe i wytrzymałościowe, aby maksymalnie zaangażować wszystkie
                partie mięśniowe. Dzięki szybkiemu tempu i różnorodności
                ćwiczeń, skutecznie spalisz kalorie i poprawisz kondycję. To
                idealna forma treningu dla kobiet, które chcą efektywnie
                wykorzystać czas i poczuć moc endorfin. Dobry wybór dla Ciebie,
                jeśli chcesz spalić kalorie, poprawić ogólną kondycję oraz
                zbudować silne i smukłe ciało.
              </p>

              <h4>
                <strong>Silne ciało</strong>
              </h4>
              <p>
                To kompleksowy trening siłowy, który pomaga wzmocnić wszystkie
                główne grupy mięśniowe, poprawiając jednocześnie wytrzymałość i
                stabilność ciała. Zajęcia są dostosowane do różnych poziomów
                zaawansowania, co pozwala na indywidualne podejście i progresję.
                Dzięki regularnym sesjom poczujesz nie tylko wzrost siły, ale
                także poprawę ogólnej kondycji i pewności siebie. Dobry wybór
                dla Ciebie, jeśli chcesz wzmocnić ciało, zbudować wytrzymałość i
                poczuć moc w każdym ruchu.
              </p>

              <h3>ZDROWE & WZMOCNIONE CIAŁO</h3>

              <h4>
                <strong>Pilates</strong>
              </h4>
              <p>
                Pilates to system ćwiczeń, który przede wszystkim wzmacnia
                mięśnie głębokie, stabilizuje kręgosłup i poprawia elastyczność.
                Wykonując precyzyjne i kontrolowane ruchy, zwiększasz świadomość
                ciała, co przekłada się na lepszą postawę i redukcję napięć
                mięśniowych. Ten trening to nie tylko sposób na smukłą sylwetkę,
                ale także na poprawę równowagi i koordynacji ruchowej. Dobry
                wybór dla Ciebie, jeśli chcesz zyskać silne i elastyczne ciało,
                poprawić postawę oraz zharmonizować ruchy z oddechem.
              </p>

              <h4>
                <strong>Pilates od zera</strong><span className="highlight"> (Dobre dla początkujących)</span> <span className="new"> (NOWOŚĆ)</span>
              </h4>
              <p>
                Zajęcia dedykowane dla początkujących, które wprowadzą Cię w
                świat pilatesu od podstaw. Skupiamy się na nauce prawidłowej
                techniki, kontrolowania oddechu oraz zrozumieniu zasad pracy z
                mięśniami głębokimi. To idealna okazja, aby w bezpieczny sposób
                rozpocząć przygodę z Pilatesem i stopniowo budować swoją siłę
                oraz elastyczność. Dobry wybór dla Ciebie, jeśli dopiero
                zaczynasz swoją przygodę z pilatesem i chcesz zbudować solidne
                podstawy, które pozwolą Ci rozwijać się dalej.
              </p>

              <h4>
                <strong>Trening Funkcjonalny</strong><span className="highlight"> (Dobre dla początkujących)</span><span className="new"> (NOWOŚĆ)</span>
              </h4>
              <p>
                Trening funkcjonalny to kompleksowe podejście do aktywności
                fizycznej, które ma na celu poprawę codziennej sprawności
                ruchowej. Wzmacnia mięśnie stabilizujące, poprawia koordynację i
                równowagę, a także zwiększa mobilność. Skupiamy się na ruchach,
                które odwzorowują aktywności dnia codziennego, co sprawia, że
                trening ten jest praktyczny i efektywny. Dobry wybór dla Ciebie,
                jeśli chcesz poprawić swoją sprawność, wzmocnić mięśnie głębokie
                i czuć się pewnie w każdym ruchu.
              </p>

              <h4>
                <strong>Zdrowy Kręgosłup</strong><span className="highlight"> (Dobre dla początkujących)</span>
              </h4>
              <p>
                Zajęcia zaprojektowane, aby zadbać o zdrowie Twojego kręgosłupa.
                Wzmacniamy mięśnie głębokie, poprawiamy postawę i uczymy
                prawidłowych ruchów, co pomaga zmniejszyć bóle pleców i poprawić
                samopoczucie. Idealne dla kobiet, które chcą mieć zdrowy i silny
                kręgosłup oraz cieszyć się swobodnym ruchem. Dobry wybór dla
                Ciebie, jeśli chcesz wzmocnić plecy, poprawić postawę i zadbać o
                zdrowie kręgosłupa na dłużej.
              </p>

              <h4>
                <strong>Stretching</strong>
              </h4>
              <p>
                Stretching to zajęcia, które koncentrują się na rozciąganiu
                mięśni, poprawie elastyczności i zwiększaniu zakresu ruchu. W
                tym treningu łączymy również elementy siły i wzmocnienia mięśni,
                co pozwala na stopniowy progres i osiąganie coraz lepszych
                rezultatów. Regularne rozciąganie pomaga zredukować napięcia w
                ciele i poprawić postawę. Dobry wybór dla Ciebie, jeśli chcesz
                poprawić elastyczność, wzmocnić mięśnie i cieszyć się większym
                zakresem ruchu.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Offer;
