import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Box,
} from "@mui/material";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "../assets/style/Timetable.css";
import SignUpForLessonModal from "../components/Timetable/SignUpForLessonModal";
import StoreModal from "../components/StoreModal";
import SingleHour from "../components/Timetable/SingleHour";

const TimeTable = () => {
  const [signUpForLessonOpen, setSignUpForLessonOpen] = useState(false);
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const [values, setValues] = useState({});
  const [lessonData, setLessonData] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [error, setError] = useState(null);
  const [signUpSucess, setSignUpSucess] = useState(false);
  const [signUpError, setSignUpError] = useState({});
  const [weekOffset, setWeekOffset] = useState(0);
  const [scheduleEndpoint, setScheduleEndpoint] = useState(
    `${process.env.REACT_APP_BACKEND_ADDRESS}/lesson_schedule?mode=hour_by_hour&flat=False&week_offset=${weekOffset}`
  );
  const [weekDate, setWeekDate] = useState({});
  const currentWeekEndpoint = `${process.env.REACT_APP_BACKEND_ADDRESS}/week_date?week_offset=${weekOffset}`;

  useEffect(() => {
    axios(scheduleEndpoint)
      .then((response) => {
        setSchedule(response.data);
        setError(null);
      })
      .catch(setError);
  }, [signUpSucess, scheduleEndpoint]);

  useEffect(() => {
    axios(currentWeekEndpoint)
      .then((response) => {
        setWeekDate({ ...response.data });
        setScheduleEndpoint(
          `${process.env.REACT_APP_BACKEND_ADDRESS}/lesson_schedule?mode=hour_by_hour&flat=False&week_offset=${weekOffset}`
        );
      })
      .catch(setError);
  }, [weekOffset]);

  useEffect(() => {
    if (signUpSucess || Object.keys(signUpError).length !== 0) {
      const timer = setTimeout(() => {
        setSignUpSucess(false);
        setSignUpError({});
      }, 3000); // The alert will disappear after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [signUpSucess, signUpError]);

  if (schedule.length === 0) return <p>Loading</p>;
  if (error) return <p>An error occurred</p>;

  return (
    <section id="timetable" className="d-flex align-items-center">
      <div className="container">
        <div className="section-title">
          <h2>Grafik / Zapisy</h2>
        </div>
        <Dialog
          open={signUpSucess || Object.keys(signUpError).length !== 0}
          onClose={() => {
            setSignUpSucess(false);
            setSignUpError({});
          }}
          PaperProps={{
            style: {
              padding: "10px",
              borderRadius: "10px",
              textAlign: "center",
              width: "400px",
              backgroundColor: "rgba(255, 255, 255, 0.9)", // More transparent
              boxShadow: "none",
            },
          }}
        >
          <DialogContent>
            {signUpSucess && (
              <Alert severity="success" style={{ marginBottom: "15px" }}>
                Zapisano na zajęcia!
              </Alert>
            )}
            {Object.keys(signUpError).length !== 0 && (
              <Alert
                severity={signUpError.type}
                style={{ marginBottom: "15px" }}
              >
                {signUpError.message}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSignUpSucess(false);
                  setSignUpError({});
                }}
                style={{ padding: "10px 60px", borderRadius: "5px" }} // Wider button
              >
                OK
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <div className="table-responsive">
          <table className="table table-bordered text-center white-table">
            <thead>
              <tr className="bg-light-gray">
                <th className="text-uppercase" colSpan="1">
                  <span>
                    <a
                      className="left"
                      title="Poprzedni"
                      onClick={() => setWeekOffset(weekOffset - 1)}
                    >
                      <FaAngleLeft />
                    </a>
                  </span>
                </th>
                <th className="text-uppercase" colSpan="5">
                  {weekDate.start} - {weekDate.end}
                </th>
                <th className="text-uppercase" colSpan="1">
                  <span>
                    <a
                      className="right"
                      title="Dalej"
                      onClick={() => setWeekOffset(weekOffset + 1)}
                    >
                      <FaAngleRight />
                    </a>
                  </span>
                </th>
              </tr>
              <tr className="bg-light-gray">
                <th className="text-uppercase">Poniedziałek</th>
                <th className="text-uppercase">Wtorek</th>
                <th className="text-uppercase">Środa</th>
                <th className="text-uppercase">Czwartek</th>
                <th className="text-uppercase">Piątek</th>
                <th className="text-uppercase">Sobota</th>
                <th className="text-uppercase">Niedziela</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(schedule.objects).map((key) => {
                let lessons = schedule.objects[key];
                return (
                  <SingleHour
                    key={key}
                    hour={lessons}
                    setSignUpForLessonOpen={setSignUpForLessonOpen}
                    setValues={setValues}
                    values={values}
                    setLessonData={setLessonData}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <SignUpForLessonModal
        open={signUpForLessonOpen}
        onClose={() => setSignUpForLessonOpen(false)}
        setsignUpSucess={setSignUpSucess}
        setSignUpError={setSignUpError}
        lessonData={lessonData}
        setLessonData={setLessonData}
      />
      <StoreModal
        open={storeModalOpen}
        onClose={() => setStoreModalOpen(false)}
      />
    </section>
  );
};

export default TimeTable;
