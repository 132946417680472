import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Tabs,
  Tab,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAuthStore } from "../store";
import UserInfoForm from "./UserInfo/UserInfoForm";
import ReauthenticateModal from "./UserInfo/ReauthenticateModal";
import AllPassesTable from "./UserInfo/AllPassesTable";
import LessonsTable from "./UserInfo/LessonsTable";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: 800 }, // Increase the width for both xs and sm breakpoints
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const UserModal = ({ open, handleClose, user, showAlert }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabIndex, setTabIndex] = useState(0);
  const [reauthenticateOpen, setReauthenticateOpen] = useState(false);
  const { lessons, setUserDetails, setLessons, passes, setPasses } =
    useAuthStore((state) => ({
      lessons: state.lessons,
      setUserDetails: state.setUserDetails,
      setLessons: state.setLessons,
      passes: state.passes,
      setPasses: state.setPasses,
    }));

  useEffect(() => {
    if (user) {
      setUserDetails({
        email: user.email,
        name: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
      });
    }
  }, [user, setUserDetails]);

  useEffect(() => {
    const fetchLessons = async () => {
      if (!user) {
        return;
      }
      const idToken = await user.getIdToken();
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_ADDRESS}/user/me/lessons`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const data = await response.json();
        if (Array.isArray(data.objects)) {
          setLessons(data.objects);
        } else {
          console.error("Invalid lessons data format", data);
          setLessons([]);
        }
      } catch (error) {
        console.error("Failed to fetch lessons", error);
        setLessons([]);
      }
    };

    fetchLessons();
  }, [user, setLessons]);

  useEffect(() => {
    const fetchPasses = async () => {
      if (!user) {
        return;
      }
      const idToken = await user.getIdToken();
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_ADDRESS}/pass/mine?active=true`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const data = await response.json();
        if (Array.isArray(data.objects)) {
          setPasses(data.objects);
        } else {
          console.error("Invalid passes data format", data);
          setPasses([]);
        }
      } catch (error) {
        console.error("Failed to fetch passes", error);
        setPasses([]);
      }
    };

    fetchPasses();
  }, [user, setPasses]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="user-modal-title"
        aria-describedby="user-modal-description"
      >
        <Box sx={modalStyle}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Moje zajęcia" />
            <Tab label="Dostępne karnety" />
            <Tab label="Moje dane" />
          </Tabs>
          {tabIndex === 0 && (
            <Box>
              <Typography variant="h6" component="h2">
                Moje zajęcia
              </Typography>
              <LessonsTable lessons={lessons} />
            </Box>
          )}
          {tabIndex === 1 && (
            <Box>
              <Typography variant="h6" component="h2">
                Karnety
              </Typography>
              <AllPassesTable />
            </Box>
          )}
          {tabIndex === 2 && (
            <Box>
              <UserInfoForm
                user={user}
                showAlert={showAlert}
                handleClose={handleClose}
                setReauthenticateOpen={setReauthenticateOpen}
              />
            </Box>
          )}
        </Box>
      </Modal>

      <ReauthenticateModal
        open={reauthenticateOpen}
        handleClose={() => setReauthenticateOpen(false)}
        showAlert={showAlert}
        handleCloseMainModal={handleClose}
      />
    </>
  );
};

export default UserModal;
