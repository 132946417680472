import React, { useState, useEffect } from "react";
import LoginModal from "./components/Login";
import RegistrationModal from "./components/Registration";
import UserModal from "./components/UserModal";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { auth } from "./firebase-config";
import { onAuthStateChanged, signOut } from "firebase/auth";
import "./assets/style/Header.css";
import { useAuthStore } from "./store";

/**
 * Scrolls to an element with header offset
 */
const scrollto = (el) => {
  let header = document.getElementById("header");
  let offset = header.offsetHeight;

  let elementPos = document.querySelector(el).offsetTop;
  window.scrollTo({
    top: elementPos - offset,
    behavior: "smooth",
  });
};

const NavLink = (props) => {
  const handleClick = (e) => {
    if (document.querySelector(props.href)) {
      e.preventDefault();
      scrollto(props.href);
    }
  };
  if (props.active) {
    return (
      <a
        className="nav-link scrollto active"
        href={props.href}
        onClick={props.onClick === undefined ? handleClick : props.onClick}
      >
        {props.children}
      </a>
    );
  } else {
    return (
      <a
        className="nav-link scrollto"
        href={props.href}
        onClick={props.onClick === undefined ? handleClick : props.onClick}
      >
        {props.children}
      </a>
    );
  }
};

const NavToggle = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isActive, setActive] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isRegistrationOpen, setRegistrationOpen] = useState(false);
  const [isUserModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const { clearUserDetails, isAuthenticated, user, setUser } = useAuthStore(
    (state) => ({
      clearUserDetails: state.clearUserDetails,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      setUser: state.setUser,
    })
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const toggleChangeState = () => {
    setActive(!isActive);
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const handleClickOutside = (event) => {
    if (!document.getElementById("navbar").contains(event.target)) {
      setActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLoginOpen = () => setLoginOpen(true);
  const handleLoginClose = () => setLoginOpen(false);

  const handleRegistrationOpen = () => setRegistrationOpen(true);
  const handleRegistrationClose = () => setRegistrationOpen(false);

  const handleUserModalOpen = () => setUserModalOpen(true);
  const handleUserModalClose = () => setUserModalOpen(false);

  const handleLogout = () => {
    signOut(auth).then(() => {
      setUser(null);
      setAlert({
        open: true,
        message: "Wylogowano pomyślnie",
        severity: "success",
      });
      clearUserDetails();
    });
  };

  const showAlert = (message, severity) => {
    setAlert({ open: true, message, severity });
  };

  const closeAlert = () => {
    setAlert({ open: false, message: "", severity: "" });
  };

  const isMobile = width <= 720;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const buttonStyle = {
    borderRadius: 20,
    backgroundColor: "#5c4033",
    padding: "4px 10px",
    fontSize: "10px",
  };

  return (
    <>
      {isMobile ? (
        <nav id="navbar" className={`${isActive ? "navbar-mobile" : "navbar"}`}>
          {isActive && (
            <ul className="navbar-mobile dropdown" onClick={toggleChangeState}>
              <li>
                <NavLink href="#about-us">o nas</NavLink>
              </li>
              <li>
                <NavLink href="#timetable">GRAFIK / ZAPISY</NavLink>
              </li>
              <li>
                <NavLink href="#pricing">cennik</NavLink>
              </li>
              <li>
                <NavLink href="#offer">oferta</NavLink>
              </li>
              <li>
                <NavLink href="#contact">kontakt</NavLink>
              </li>
              {isAuthenticated && (
                <>
                  <li>
                    <NavLink
                      href="#hero"
                      onClick={handleLogout}
                      style={{ fontWeight: "bold" }}
                    >
                      Wyloguj się
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          )}
          <div className="mobile-buttons">
            {isAuthenticated && (
              <IconButton
                color="inherit"
                onClick={handleUserModalOpen}
                className="icon-button"
              >
                <AccountCircleIcon />
              </IconButton>
            )}
            {!isAuthenticated && (
              <IconButton
                color="inherit"
                onClick={handleLoginOpen}
                className="icon-button"
              >
                <LoginIcon />
              </IconButton>
            )}
            <IconButton
              color="inherit"
              onClick={toggleChangeState}
              className="mobile-nav-toggle"
            >
              <MenuIcon />
            </IconButton>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleUserModalOpen}>Dane</MenuItem>
            <MenuItem onClick={handleLogout} style={{ fontWeight: "bold" }}>
              Wyloguj się
            </MenuItem>
          </Menu>
        </nav>
      ) : (
        <div>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <NavLink href="#about-us">o nas</NavLink>
              </li>
              <li>
                <NavLink href="#timetable">GRAFIK / ZAPISY</NavLink>
              </li>
              <li>
                <NavLink href="#pricing">cennik</NavLink>
              </li>
              <li>
                <NavLink href="#offer">oferta</NavLink>
              </li>
              <li>
                <NavLink href="#contact">kontakt</NavLink>
              </li>
              <li>
                {isAuthenticated ? (
                  <>
                    <IconButton
                      color="inherit"
                      onClick={handleUserModalOpen}
                      className="icon-button"
                    >
                      <AccountCircleIcon />
                    </IconButton>
                    <Button
                      variant="contained"
                      style={buttonStyle}
                      onClick={handleLogout}
                    >
                      Wyloguj się
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    style={buttonStyle}
                    startIcon={
                      loading ? <CircularProgress size={20} /> : <LoginIcon />
                    }
                    onClick={handleLoginOpen}
                  >
                    {loading ? "Logowanie..." : "Zaloguj się"}
                  </Button>
                )}
              </li>
            </ul>
          </nav>
        </div>
      )}
      <LoginModal
        open={isLoginOpen}
        handleClose={handleLoginClose}
        showAlert={showAlert}
      />
      <RegistrationModal
        open={isRegistrationOpen}
        handleClose={handleRegistrationClose}
        showAlert={showAlert}
      />
      <UserModal
        open={isUserModalOpen}
        handleClose={handleUserModalClose}
        user={user}
        showAlert={showAlert}
      />
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={closeAlert} severity={alert.severity} variant="filled">
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

const Header = (props) => {
  return (
    <div className="container d-flex align-items-center ">
      <h1 className="logo me-auto">
        <a href="#">nat•chnienie</a>
      </h1>
      <NavToggle />
    </div>
  );
};

export default Header;
