import React, { useState } from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase-config";
import { useAuthStore } from "./store";

import "./vendor/bootstrap/css/bootstrap.min.css";
import "./vendor/bootstrap-icons/bootstrap-icons.css";
import "./vendor/boxicons/css/boxicons.min.css";
import "./vendor/remixicon/remixicon.css";
import "./vendor/swiper/swiper-bundle.min.css";
import "./assets/style/General.css";
import "./assets/style/BackToTop.css";
import "./assets/style/Footer.css";
import "./assets/style/Sections.css";
import Header from "./Header";
import Hero from "./Hero";
import AboutUs from "./AboutUs";
import Pricing from "./Pricing";
import Offer from "./Offer";
import Contact from "./Contact";
import TimeTable from "./pages/TimeTable";
import Footer from "./Footer";
import BackToTop from "./BackToTop";
import LoginPage from "./components/Login";
import RegistrationPage from "./components/Registration";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Admin from "./Admin";
import CookiesAlert from "./CookieAlert";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Statute from "./pages/Statute";

const select = (el, all = false) => {
  el = el.trim();
  if (all) {
    return [...document.querySelectorAll(el)];
  } else {
    return document.querySelector(el);
  }
};

const changeHeaderColor = () => {
  let selectHeader = select("#header");
  if (selectHeader !== null) {
    if (window.scrollY > 100) {
      selectHeader.classList.add("header-scrolled");
    } else {
      selectHeader.classList.remove("header-scrolled");
    }
  }
};

const navbarlinksActive = () => {
  let navbarlinks = select("#navbar .scrollto", true);
  let position = window.scrollY + 200;
  navbarlinks.forEach((navbarlink) => {
    if (!navbarlink.hash) return;
    let section = select(navbarlink.hash);
    if (!section) return;
    if (
      position >= section.offsetTop &&
      position <= section.offsetTop + section.offsetHeight
    ) {
      navbarlink.classList.add("active");
    } else {
      navbarlink.classList.remove("active");
    }
  });
};

const SetScrollHanlder = (handler) => {
  useEffect(() => {
    const handleScroll = (event) => {
      handler();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
};

const addBackToTop = () => {
  let backtotop = select(".back-to-top");
  if (backtotop) {
    if (window.scrollY > 100) {
      backtotop.classList.add("active");
    } else {
      backtotop.classList.remove("active");
    }
  }
};

const removePreloader = () => {
  let preloader = select("#preloader");
  if (preloader) {
    window.addEventListener("load", () => {
      preloader.remove();
    });
  }
};

const init = () => {
  AOS.init({
    duration: 1000,
    easing: "ease-in-out",
    once: true,
    mirror: false,
  });
  removePreloader();
};

const HomePage = () => {
  const [cookiePolicyAccepted, setCookiePolicyAccepted] = useState(
    document.cookie.includes("cookiePolicyAccepted=true")
  );

  function handleAccept() {
    setCookiePolicyAccepted(true);
  }
  return (
    <div>
      <CookiesAlert onAccept={handleAccept} />
      <div className="home-page-content">
        <header id="header" className="fixed-top">
          <Header />
        </header>
        <Hero />
        <main id="main">
          <AboutUs />
          <TimeTable />
          <Pricing />
          <Offer />
          <Contact />
        </main>
        <Footer />
        <BackToTop />
        {/* TODO, add after switching to redux <Preloader/> */}
      </div>
    </div>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HomePageWithAlert = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const alertMessage = queryParams.get("message");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (alertMessage) {
      setOpen(true);
    }
  }, [alertMessage]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <HomePage />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="info">
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

const PurchaseConfirmation = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get("error");

  useEffect(() => {
    if (error) {
      alert("An error occurred during the purchase process.");
    } else {
      alert("Pass was bought properly.");
    }
  }, [error]);

  return (
    <div>
      <h2>{error ? "Purchase Error" : "Purchase Successful"}</h2>
    </div>
  );
};

const App = () => {
  useEffect(() => {
    init();
  }, []);

  const setUserDetails = useAuthStore((state) => state.setUserDetails);
  const clearUserDetails = useAuthStore((state) => state.clearUserDetails);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_ADDRESS}/user/me`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setUserDetails({
            email: data.email,
            name: data.full_name.split(" ")[0] || "",
            lastName: data.full_name.split(" ")[1] || "",
            phone: data.phone,
            isAuthenticated: true,
            userId: data.id,
            firebaseUid: data.firebase_uid,
          });
        }
      } else {
        clearUserDetails();
      }
    });

    return () => unsubscribe();
  }, [setUserDetails, clearUserDetails]);

  SetScrollHanlder(changeHeaderColor);
  SetScrollHanlder(navbarlinksActive);
  SetScrollHanlder(addBackToTop);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/statute" element={<Statute />} />
          <Route
            path="/purchase-confirmation"
            element={<PurchaseConfirmation />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
