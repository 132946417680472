import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Slide,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useAuthStore } from "../store";
import { useTheme, useMediaQuery } from "@mui/material";

const StoreModal = ({ open, onClose, lessonCategoryId, lessonId }) => {
  // Added lessonCategory as a prop
  const [availablePasses, setAvailablePasses] = useState([]);
  const [selectedPass, setSelectedPass] = useState(null);
  const [loading, setLoading] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [addedPass, setAddedPass] = useState(null);
  const [acceptRegulamin, setAcceptRegulamin] = useState(false);

  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (open) {
      fetchAvailablePasses();
    }
  }, [open]);

  const fetchAvailablePasses = async () => {
    setLoading(true);
    const idToken = await user.getIdToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/pass-type/`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
      const data = await response.json();
      if (Array.isArray(data.objects)) {
        let passes = data.objects;

        // Filter passes by lesson category if provided
        if (lessonCategoryId) {
          passes = passes.filter((pass) =>
            pass.lesson_categories_for_pass_type.some(
              (lesson) => lesson.lesson_category_id === lessonCategoryId
            )
          );
        }

        setAvailablePasses(passes);
      } else {
        console.error("Invalid passes data format", data);
        setAvailablePasses([]);
      }
    } catch (error) {
      console.error("Failed to fetch available passes", error);
      setAvailablePasses([]);
    } finally {
      setLoading(false);
    }
  };

  const handleAddToCart = (pass) => {
    setAddedPass(pass);
    setTimeout(() => {
      setSelectedPass(pass);
      setAddedPass(null);
    }, 300);
  };

  const handleRemoveFromCart = () => {
    setSelectedPass(null);
  };

  const handleSubmit = async () => {
    if (!acceptRegulamin) {
      alert("Proszę zaakceptować Regulamin.");
      return;
    }

    setPurchaseLoading(true);
    const idToken = await user.getIdToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/payment/create_order`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pass_type_name: selectedPass.name,
            lesson_id: lessonId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create order");
      }

      const data = await response.json();
      const redirectUrl = data;

      // Show alert and then redirect
      window.location.href = redirectUrl;
    } catch (error) {
      console.error("Failed to create order", error);
      alert("An error occurred during the purchase process.");
    } finally {
      setPurchaseLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "90%" : "50%", // Adjust width based on device type
          maxHeight: "80%",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Kup karnet
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid
            container
            spacing={2}
            style={{ maxHeight: "60vh", overflowY: "auto" }}
          >
            {availablePasses.map((pass) => (
              <Grid item xs={12} sm={6} key={pass.id}>
                <Slide
                  direction={addedPass?.id === pass.id ? "left" : "up"}
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ enter: 300, exit: 300 }}
                >
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{pass.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        Cena: {pass.price} zł
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Czas trwania: {Math.floor(pass.valid_duration / 86400)}{" "}
                        dni
                      </Typography>
                      {selectedPass && selectedPass.id === pass.id ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          onClick={handleRemoveFromCart}
                        >
                          Usuń z koszyka
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => handleAddToCart(pass)}
                          disabled={selectedPass !== null}
                        >
                          Dodaj do koszyka
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                </Slide>
              </Grid>
            ))}
          </Grid>
        )}
        <Box
          mt={3}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptRegulamin}
                  onChange={(e) => setAcceptRegulamin(e.target.checked)}
                  name="acceptRegulamin"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  Akceptuję{" "}
                  <a
                    href={`${process.env.PUBLIC_URL}/statute`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Regulamin
                  </a>
                </Typography>
              }
            />
            <Button variant="contained" color="error" onClick={onClose}>
              Anuluj
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end" width="100%" mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={purchaseLoading || !selectedPass || !acceptRegulamin}
            >
              {purchaseLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Kup wybrany karnet"
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default StoreModal;
