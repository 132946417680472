import React, { useState } from "react";
import { Modal, Box, TextField, Button } from "@mui/material";
import { validatePhone } from "../consts";
import { useAuthStore } from "../store";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const LoginAdditionalInfoModal = ({ open, handleClose, handleSubmit }) => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const { email } = useAuthStore((state) => ({
    email: state.email,
  }));

  const [phoneError, setPhoneError] = useState("");

  const onSubmit = () => {

    if (!validatePhone(phone)) {
      setPhoneError("Niepoprawny numer telefonu");
      return;
    } else {
      setPhoneError("");
    }

    handleSubmit({ name, lastName, phone, email });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="additional-info-modal-title"
    >
      <Box sx={modalStyle}>
        <h2 id="additional-info-modal-title">Uzupełnij swoje dane</h2>
        <TextField
          label="Imię"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Nazwisko"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Numer telefonu"
          type="text"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          margin="normal"
          error={!!phoneError}
          helperText={phoneError}
        />
        <Button
          onClick={onSubmit}
          fullWidth
          variant="contained"
          style={{
            backgroundColor: "#5c4033",
            padding: "10px 10px",
            fontSize: "15px",
            marginTop: "16px",
          }}
        >
          Prześlij
        </Button>
      </Box>
    </Modal>
  );
};

export default LoginAdditionalInfoModal;
